import { Fragment } from "react";

const Header = () => {
  // Returning header with logo, title and info icon
  return (
    <Fragment>
      <header className="header">
        <div className="header-content">
            <img
              id="header-logo"
              src={process.env.PUBLIC_URL + "/images/logo.svg"}
              alt="eye scanner logo"
            />
        </div>
      </header>
      {/* <div className="container">
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <span> View Instructions</span>
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body p-0">
                <div className="ins_box_wrapper p-5 bg-white text-center">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="instruction_box mb-4">
                        <img src={process.env.PUBLIC_URL + "/images/pd-img1.png"} alt=""/>
                        <h5>Step 1:</h5>
                        <h6 className="mb-0">if you are wearing glasses, take them Off</h6>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="instruction_box mb-4">
                        <img src={process.env.PUBLIC_URL + "/images/pd-img2.png"} alt=""/>
                        <h5>Step 2:</h5>
                        <p className="mb-1">Place a Credit Card beneath your nose</p>
                        <p className="mb-1">For the most accurate calculation, use a card sized 3.370 x 2.125 inches. (85.60 x 53.98 mm)this is the standard size of a credit card.</p>
                        <p className="mb-0">if you use a credit card for your image, please make sure you use the side with the strip facing the camera and edit out any sensitive information after you have taken the photo.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="instruction_box">
                        <img src={process.env.PUBLIC_URL + "/images/pd-img3.png"} alt=""/>
                        <h5>Step 3:</h5>
                        <p className="mb-1">Look straight ahead and take a photo</p>
                        <p>take a picture with your cell phone. For optimal results, keep your eyes open and take your photo in a well-lit area.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};

export default Header;
